import {WebStorageStateStore, UserManager} from  'oidc-client';

export const handleCallback = async () => {
  // gets generated by oidc
  let hash = window.location.hash;

  if(!hash){
    return;
  }

  const userStore = new WebStorageStateStore({store: window.localStorage});
  let userManager = new UserManager({userStore});
  await userManager.signinRedirectCallback();
  removeHash();
};

function removeHash () {
  history.pushState("", document.title, window.location.pathname + window.location.search);
}